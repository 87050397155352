import { Collapse, CollapseProps, Flex, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { SessionService, SessionStateDto, getEarningName, round } from "shared";
import { ChainIcon, SwapperIcon } from "feature/icons";
import { KeyNumberItem, KeyValueItem } from "feature/keyValueItem";
import { Lists } from "feature/list";
import { getTimeDiffSecondsStr, getTimeDiffStr } from "shared/utils/DateUtils";
import { EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { PoolState } from "feature/poolState";

const { Text } = Typography;

export type SessionStatesProps = {
  sharedSessions?: boolean
}

export const SessionStates = (props: SessionStatesProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [sessions, setSessions] = useState<SessionStateDto[]>([]);
  useEffect(() => {
    SessionService.getApiSessionActiveState({ sharedSessions: props.sharedSessions }).then((result) => {
      setSessions(result?.data ?? []);
      setLoading(false);
    });
  }, []);

  const Title = (item: SessionStateDto & {readOnly?: boolean}) => {
    return (<Flex className="flex justify-between gap-x-2" vertical={false}>      
      <Flex gap={10}>
        <div>{item.scenario?.name}</div>
        { !item.readOnly && <EyeOutlined onClick={() => navigate(`/scenarios/${item.scenario?.id}`)} />}
      </Flex>
      <Flex gap={10}>
        <ChainIcon objectId={item.scenario?.netId} />
        <SwapperIcon objectId={item.scenario?.swapperId} />
      </Flex>
    </Flex>
    );
  };

  const pnlItems = (item: SessionStateDto): CollapseProps['items'] => [
    {
      key: '0',
      label: "Подробнее",
      children: <Flex vertical>
        <KeyNumberItem label="PNL(%)" value={item.scenario?.amount ? ((item.pnl ?? 0) * 100.0) / (item.scenario?.amount!) : 0} postfix="%" />
        <KeyNumberItem label="Стартовый объем" value={item.scenario?.amount ?? 0} postfix="$" />
        <KeyNumberItem label="Текущий объем" value={item.position?.amount ?? 0} postfix="$" />
        <KeyNumberItem label="Объем хеджа" value={item.hedgeUnits ?? 0} postfix={` ${item.scenario?.token0.realSymbol ?? item.scenario?.token0.symbol}`} />
        <KeyNumberItem label="Кол-во смен пулов" value={item.poolPositionCount} />
        <KeyValueItem label="Среднее время в пуле" value={getTimeDiffSecondsStr(item.poolAvgTime!)} />
        <KeyValueItem label="Время в текущем пуле" value={getTimeDiffSecondsStr(item.poolLastTime!)} />
        {item.daysToExpiration && <KeyNumberItem label="Дней до экспирации" value={item.daysToExpiration} />}
        {item.optionsAPR && <KeyNumberItem label="Стоимость владения" value={item.optionsAPR} postfix="%" />}
        <KeyNumberItem label="APR" value={item.apr} postfix="%" />
        <KeyNumberItem label="APR текущий" value={item.currentAPR} postfix="%" />
      </Flex>,
    },
    {
      key: '1',
      label: <KeyNumberItem
        label="Реализованный PNL"
        value={item.sessionSummary?.reduce((prev, current) => ({ value: (prev.value ?? 0) + (current.value ?? 0) }))?.value ?? 0}
        postfix="$" />,
      children: item.sessionSummary?.map(x =>
        <KeyNumberItem key={x.key} label={getEarningName(x.key!) ?? x.key as string} value={x.value} postfix="$" />
      ),
    },
    {
      key: '2',
      label: <KeyNumberItem label="Нереализованный PNL" value={(item.hedgePNL ?? 0) + (item.positionPNL ?? 0)} postfix="$" />,
      children: <Flex vertical>
        <KeyNumberItem key={'positionPNL'} label="PNL позиции пула" value={item.positionPNL ?? 0} postfix="$" />
        <i><KeyNumberItem key={'currentFee'} label="&nbsp; в т.ч. текущий доход пула" value={item.currentFee ?? 0} postfix="$" /></i>
        <i><KeyNumberItem key={'PnlMinusCurrentFee'} label="&nbsp; в т.ч. стоимость актива" value={(item.positionPNL ?? 0) - (item.currentFee ?? 0)} postfix="$" /></i>
        <KeyNumberItem key={'hedgePNL'} label="PNL хэджа" value={item.hedgePNL ?? 0} postfix="$" />
      </Flex>,
    },
  ];

  const Body = (item: SessionStateDto & {readOnly?: boolean}) => {
    return (
      <Flex className="flex justify-between" vertical>        
        <Flex className="flex justify-between gap-x-2" flex={"1"}>
          <Text code>{`${item.position?.lowerPrice}-${item.position?.upperPrice}`}</Text>
          <Flex className="items-baseline gap-x-2">
            <Text type="secondary">{`${item.scenario?.token0.symbol}-${item.scenario?.token1.symbol}`}</Text>
            <Text code>{(item.scenario?.feeTier ?? 0) / 10000}%</Text>
          </Flex>
        </Flex>
        <KeyNumberItem label="Текущая цена" value={item.currentPrice ?? 0} postfix="$" />
        {item.readOnly && <KeyValueItem label="Пользователь" value={item.userName} />}
        <KeyNumberItem label="PNL" value={item.pnl ?? 0} postfix="$" style={{ fontSize: '16px', fontWeight: 'bold' }} />

        {item.scenario?.strategy.hasOwnProperty("EdgePoints") &&
          <PoolState
            lowerPrice={item.position?.lowerPrice!}
            upperPrice={item.position?.upperPrice!}
            currentPrice={item.currentPrice!}
            currentCexPrice={item.currentCexPrice!}
            shift={item.position?.shift!}
            edgePoints={item.scenario?.strategy["EdgePoints"]}
          />}
        <KeyValueItem
          label="Длительность сессии"
          value={getTimeDiffStr(new Date(Date.parse(item.dateStart!)), new Date())}
        />
        <Collapse style={{ marginTop: 5 }} size="small" items={pnlItems(item)} />
      </Flex>
    );
  };

  return (
    <Lists
      loading={loading}
      data={sessions.map((d) => {
        return {
          key: d.id!,
          title: <Title {...d } readOnly={props.sharedSessions}/>,
          description: <Body {...d}  readOnly={props.sharedSessions}/>,
        };
      })}
    />
  );
};
