import { ScenarioDto, SliceDto } from "shared/api";
import { CalculationResult, LiquidityResult, OptimizeOptions, Point } from "../model";
import { round } from "shared/utils";
import { erf } from 'mathjs'


export function getLiquidityByAmountX(x: number, price: number, priceHigh: number): number {
  const L = (x * Math.sqrt(price) * Math.sqrt(priceHigh)) / (Math.sqrt(priceHigh) - Math.sqrt(price));
  return L;
}

export function getLiquidityByAmountY(y: number, price: number, priceLow: number): number {
  if (price === priceLow) {
    return 0;
  }
  const L = y / (Math.sqrt(price) - Math.sqrt(priceLow));
  return L;
}

export function getX(L: number, price: number, priceHigh: number): number {
  if (price === priceHigh) {
    return 0;
  }
  const x = L * ((Math.sqrt(priceHigh) - Math.sqrt(price)) / (Math.sqrt(price) * Math.sqrt(priceHigh)));
  return x;
}

export function getY(L: number, price: number, priceLow: number): number {
  const y = L * (Math.sqrt(price) - Math.sqrt(priceLow));
  return y;
}

export function getAmountsAtRangeEnds(L: number, priceLow: number, priceHigh: number): void {
  const tmp1 = getX(L, priceLow, priceHigh) / 10 ** 12;
  const tmp2 = getY(L, priceHigh, priceLow) / 10 ** 12;
  console.log(`${tmp1.toFixed(6)} ETH, если ↓ / ${tmp2.toFixed(6)} USDT если ↑`);
}

export function getAmountsAtRangeCenter(L: number, priceLow: number, priceHigh: number): void {
  const centerPrice = priceLow + (priceHigh - priceLow) / 2;
  const tmp1 = getX(L, centerPrice, priceHigh) / 10 ** 12;
  const tmp2 = getY(L, centerPrice, priceLow) / 10 ** 12;
  console.log(`${tmp1.toFixed(6)} ETH, если ↓ / ${tmp2.toFixed(6)} USDT если ↑`);
}

export function getAmountsToInit(x: number, y: number, price: number, priceLow: number, priceHigh: number): LiquidityResult | null {
  if (x * y !== 0) {
    throw new Error("Должно быть x = 0 или y = 0, расчет происходит только по одному активу");
  }

  const accuracyLevel = 11235;
  const accuracy = 0.1;
  const correspondingToAccuracy: LiquidityResult[] = [];

  for (let inspNum = 0; inspNum <= accuracyLevel; inspNum++) {
    const amountRatio = inspNum / accuracyLevel;

    if (x !== 0) {
      const yOut = parseFloat((x * amountRatio * price).toFixed(10));
      const xOut = parseFloat((x * (1 - amountRatio)).toFixed(10));
      const L = getLiquidityByAmountX(xOut, price, priceHigh) * 10 ** 12;
      const yCalculated = getY(L, price, priceLow) / 10 ** 12;

      if (yCalculated > yOut) {
        continue;
      }

      const diffVal = yOut - yCalculated;

      if (yCalculated > 0 && diffVal / yCalculated < accuracy) {
        correspondingToAccuracy.push({ calc_accuracy: diffVal / yCalculated, liquidity: L, x: xOut, y: yCalculated });
      }
    } else if (y !== 0) {
      const xOut = parseFloat((y * amountRatio / price).toFixed(10));
      const yOut = parseFloat((y * (1 - amountRatio)).toFixed(10));
      const L = getLiquidityByAmountY(yOut, price, priceLow) * 10 ** 12;
      const xCalculated = getX(L, price, priceHigh) / 10 ** 12;

      if (xCalculated > xOut) {
        continue;
      }

      const diffVal = xOut - xCalculated;

      if (xCalculated > 0 && diffVal / xCalculated < accuracy) {
        correspondingToAccuracy.push({ calc_accuracy: diffVal / xCalculated, liquidity: L, x: xCalculated, y: yOut });
      }
    }
  }

  if (correspondingToAccuracy.length > 0) {
    return correspondingToAccuracy.reduce((prev, curr) => (prev.calc_accuracy < curr.calc_accuracy ? prev : curr));
  }

  return null;
}


export function calculateOptionPrice(
  S: number,     // Текущая цена актива
  K: number,     // Цена исполнения
  T: number,     // Время до экспирации (в годах)
  r: number,     // Безрисковая ставка
  sigma: number, // Волатильность (годовая)
  isCall: boolean // true для call, false для put
): number {
  if (T === 0)  // На день экспирации
  {
        if (isCall){
            return Math.max(S - K, 0)  // Внутренняя стоимость call опциона
        }
        else
            return Math.max(K - S, 0) // Внутренняя стоимость put опциона
  }
  const d1 = (Math.log(S / K) + (r + 0.5 * sigma * sigma) * T) / (sigma * Math.sqrt(T));
  const d2 = d1 - sigma * Math.sqrt(T);

  const cdf = (x: number) => 0.5 * (1 +  erf(x / Math.sqrt(2)));

  if (isCall) {
      return S * cdf(d1) - K * Math.exp(-r * T) * cdf(d2);
  } else {
      return K * Math.exp(-r * T) * cdf(-d2) - S * cdf(-d1);
  }
}

function calculateSigma( 
          S: number,     // Текущая цена актива
          K: number,     // Цена исполнения
          T: number,     // Время до экспирации (в годах))
          P: number      // Ask цена 
): number {
  let sigma = 0.6; //60%
  let p = 0;
  let low = 0
  let high = 3
  let mid = (low + high) / 2.0;
  let cnt = 0
  do
  {
      sigma = mid
      p = calculateOptionPrice(S, K, T, 0.01, sigma, false);
      if (p - P < 0) {
        low = mid;
      } else {
        high = mid;
      }  
      mid = (low + high) / 2.0;
      cnt++;
      if(cnt>10) break;
  } while (Math.abs(p - P)>0.5)
  return sigma;
}

function calculateFutureHeadge( 
  amount: number,
  priceOnInit: number,
  priceLow: number,
  priceHigh: number,
  liquidity: number
): number {
  let low = 0;
  let high = amount / priceOnInit;
  let mid = (low + high) / 2.0;
  let iteration = 0;
  const amountX = getX(liquidity, priceLow, priceHigh) / 10 ** 12; // 18-6
  const amountY = getY(liquidity, priceHigh, priceLow) / 10 ** 12; // 18-6
  // Используем метод бисекции для поиска оптимального значения
  while (iteration < 20) { 
    const leftPnl =   (amountX * priceLow) - amount + (priceOnInit - priceLow) * mid;
    const rightPnl =  amountY - amount + (priceOnInit - priceHigh) * mid;
    const difference = leftPnl - rightPnl;

    if (Math.abs(difference) <= 0.01) {
      break;
    }
    if (Math.abs(low - high) <= 0.01) {
      mid = high - mid <= 0.01 ? high : low
      break;
    }
    if (difference < 0) {
      low = mid;
    } else {
      high = mid;
    }

    mid = (low + high) / 2.0;
    iteration += 1;
  }
  return mid;
}

export function calculate(
  amount: number,
  priceOnInit: number,
  priceLow: number,
  priceHigh: number,
  hedgeAmount: number,
  strikePrice: number,
  optionAskPrice: number,
  optionBidPrice: number,
  expiryDaysCount: number,
  feePerDay: number,
  executionDay: number
): Point[] {
  let balance = amount;
  let x = 0; // ETH
  let y = balance; // USDT
  const step = (priceHigh - priceLow)/40;
  const result = getAmountsToInit(x, y, priceOnInit, priceLow, priceHigh);
  const feePerDayValue = amount * (feePerDay / 100)
  const points: Point[] = [];
  if (!result) {
    throw new Error("No valid result found for initialization");
  }
  const T = ((expiryDaysCount - executionDay)+(1/1440))/365;
  
  const futureHedgeAmount = calculateFutureHeadge(amount, priceOnInit, priceLow, priceHigh, result.liquidity);
  const sigma = calculateSigma(priceOnInit, strikePrice, expiryDaysCount/365, optionAskPrice);
  /*
  if(expiryDaysCount>100){
  debugger
  }*/
  for (let price = priceLow; price <= priceHigh; price += step) {
    const amountX = getX(result.liquidity, price, priceHigh) / 10 ** 12; // 18-6
    const amountY = getY(result.liquidity, price, priceLow) / 10 ** 12; // 18-6
    balance = amountX * price + amountY;
    
    const op = calculateOptionPrice(price, strikePrice, T, 0.01, sigma, false);
     
    const dx = strikePrice - priceOnInit;
    const daylyKpd = (optionAskPrice - (dx<0?0:dx)) / (expiryDaysCount === 0?1:expiryDaysCount ) * hedgeAmount 
    const kpd = daylyKpd * executionDay;
    const spred = Math.abs(optionAskPrice - optionBidPrice);
    const spredDx = spred / expiryDaysCount;
    const spredPerDay = executionDay ===1? spred: spred - spredDx * executionDay;

    const hedgePNL =(op - optionAskPrice - spredPerDay) * hedgeAmount;

    const hedgeConditionValue = hedgeAmount * (strikePrice - price);
    const hedgeSum = kpd * hedgeAmount //optionPrice * hedgeAmount;
    const hedgeValue = (hedgeConditionValue < 0 ? -hedgeSum : hedgeConditionValue - hedgeSum) + feePerDayValue * expiryDaysCount;
    const totalValue = amountX * price + amountY + hedgeValue; 
    const pnl = totalValue - amount - spredPerDay;
    
    //const hedgeConditionValuePerDay =  strikePrice<=priceOnInit && price > priceOnInit?0: (hedgeAmount * ( priceOnInit - (hedgeConditionValue < 0 ? strikePrice: price)));
    
    
    const hedgeConditionValuePerDay =  strikePrice<=priceOnInit ? (price > strikePrice?0: (hedgeAmount * ( strikePrice - price))):(hedgeAmount * ( priceOnInit - Math.min(price , strikePrice)));
   
    const hedgeValuePerDay = hedgeConditionValuePerDay - kpd + feePerDayValue * executionDay;
    //const totalValuePerDay = amountX * price + amountY + hedgeValuePerDay;    
    //const pnlPerDay = totalValuePerDay - amount - spredPerDay;
    const totalValuePerDay = amountX * price + amountY + hedgePNL;    
    const  positionPNL = balance - amount;
    const  positionPNLWithFee = positionPNL + feePerDayValue * executionDay;
    const pnlPerDay = positionPNLWithFee + hedgePNL;
    
    const fpnl = positionPNLWithFee + (priceOnInit - price)*futureHedgeAmount
    if(points.length >0 && Math.abs(points[points.length-1].pnlPerDay - pnlPerDay)>40)      
    {
//debugger;
   }
    points.push({ price, pnl:pnlPerDay, pnlPerDay: fpnl });
  }

  return points;
}

// Поиск оптимального размера хеджа
export function optimizeHedgeAmount(
  amount: number,
  priceOnInit: number,
  priceLow: number,
  priceHigh: number,
  strikePrice: number,
  optionAskPrice: number,
  optionBidPrice: number,
  expiryDaysCount: number,
  feePerDay: number,
  executionDay: number,
  options: OptimizeOptions
): [number, Point[]] {
  let low = options.low
  let high = options.high
  let mid = (low + high) / 2.0;
  let iteration = 0;
  let result: Point[] = []
  // Используем метод бисекции для поиска оптимального значения
  while (iteration < options.maxIterations) {
    result = calculate(amount, priceOnInit, priceLow, priceHigh, mid, strikePrice, optionAskPrice, optionBidPrice, expiryDaysCount, feePerDay, executionDay);
    const leftPnl = result[0].pnl;
    const rightPnl = result[result.length - 1].pnl;
    const difference = leftPnl - rightPnl;

    if (Math.abs(difference) <= options.tolerance) {
      break;
    }
    if (Math.abs(low - high) <= options.tolerance) {
      mid = high - mid <= options.tolerance ? high : low
      break;
    }
    if (difference < 0) {
      low = mid;
    } else {
      high = mid;
    }

    mid = (low + high) / 2.0;
    iteration += 1;
  }
  return [Math.round(mid * 100) / 100, result];
}

export const calculate_best = (state: ScenarioDto, poolPrice: number, data: SliceDto[], executionDay: number): CalculationResult[] => {
  const currentPrice = round(poolPrice, 2);
  const priceRange = currentPrice * (state.strategy.ChannelWidthPercent / 100);
  const shift = state.strategy.EdgePoints[1];
  const options: OptimizeOptions = {
    low: 0.01,
    high: (state.amount / currentPrice) *2,
    maxIterations: 100,
    tolerance: 0.01
  }
  let list: CalculationResult[] = []

  if (data && data.length > 0) {
    //for (let shift = 25; shift <= 50; shift++) {
    let high = round(currentPrice + priceRange * ((100 - shift) / 100), 2);
    let low = round(currentPrice - priceRange * ((shift) / 100), 2);

    let maxPnlItem: any = {}
    data?.forEach(slice => {
      let hedge_amount = 0;
      const expiration_date = Date.parse(slice.expirationDateString + 'T00:00:00Z');
      const days_to_expiration = Math.ceil((expiration_date - (new Date() as any)) / 24 / 3600 / 1000);
      if(days_to_expiration > 20) 
      {
      slice.sliceTable = slice.sliceTable?.reverse() // для корректного расчета объема хеджирования
      slice.sliceTable?.filter(x =>
        x.type === "PUT"
      ).forEach(x => {
        
        if ((x.strikePrice! >=-(currentPrice - currentPrice * 0.5)) && (x.strikePrice! <(currentPrice * 1.5)) &&  x.lowestAskPrice && x.lowestAskPrice > 0 /*&& x.lowestAskPrice! * hedge_amount < state.amount * 0.2*/) {
          let points: Point[] = [];
          //if (hedge_amount === 0) {
          let score = -state.amount
          let best_shift = shift
          let flag = false 
          const shift_ = shift
          //for (let shift_ = 30; shift_ <= 70; shift_=shift_+ 10) {
            const high_ = round(currentPrice + priceRange * ((100 - shift_) / 100), 2);
            const low_ = round(currentPrice - priceRange * ((shift_) / 100), 2);
            //if (x.strikePrice! > low_ && x.strikePrice! < high_) {
              const [h_, points_] = optimizeHedgeAmount(
                state.amount,
                currentPrice,
                low_,
                high_,
                x.strikePrice!,
                x.lowestAskPrice!,
                x.highestBidPrice!,
                days_to_expiration,
                0,
                executionDay,
                options);
              const aPnl_ = points_[0].pnl;
              const bPnl_ = points_[points_.length - 1].pnl;
              const minPnl_ = Math.min(...points_.map(p => p.pnl));
              const maxPnl_ = Math.max(...points_.map(p => p.pnl));
              // const score_ = (aPnl_ + bPnl_ + minPnl_)
              const score_ = minPnl_
              if (score_ > score) {
                flag = true
                best_shift = shift_;
                high = high_;
                low = low_;
                hedge_amount = h_;
                points = points_;
                score = score_
              //}
            //}
          }
          if (flag) {
            /*
            const [h, points] = optimizeHedgeAmount(
              state.amount,
              currentPrice,
              low,
              high,
              x.strikePrice!,
              x.lowestAskPrice!,
              days_to_expiration,
              0,
              options);
            hedge_amount = h;
            //}
  
            points = calculate(state.amount,
              currentPrice,
              low,
              high,
              hedge_amount,
              x.strikePrice!,
              x.lowestAskPrice!,
              days_to_expiration,
              0);
             */
            const aPnl = points[0].pnl;
            const bPnl = points[points.length - 1].pnl;
            const minPnl = Math.min(...points.map(p => p.pnl));
            const maxPnl = Math.max(...points.map(p => p.pnl));




            const item = {
              expirationDate: slice.expirationDateString,
              item: x,
              hedgeUnits: hedge_amount,
              shift: best_shift,
              minPnl: Math.min(...points.map(p => p.pnl)),
              maxPnl: Math.max(...points.map(p => p.pnl)),
              kpd: 0,
              points: [...points], 
              daysToExpiration: days_to_expiration
            };

            ////const kpd2 = item.minPnl / (days_to_expiration + 1)
            ////item.kpd = kpd2;
            ////item.kpd = (item.minPnl / x.lowestAskPrice!) / days_to_expiration
            //item.kpd = -(x.lowestAskPrice! / item.item.strikePrice!) / days_to_expiration
            const dx = item.item.strikePrice! - currentPrice;
            item.kpd = -hedge_amount * (x.lowestAskPrice! - (dx<0?0:dx)) / days_to_expiration
            if(item.kpd>0)
            {
              //debugger
            }
            //for (let index = 0.2; index < 2; index+=0.2) {
            /*const hedge = hedge_amount * 1;//;
            points = calculate(state.amount,
              currentPrice,
              low,
              high,
              hedge,
              x.strikePrice!,
              x.lowestAskPrice!,
              days_to_expiration,
              0);
            
            const _kpd1 = (maxPnl / x.lowestAskPrice!) * days_to_expiration;
            const _kpd2 = minPnl / (days_to_expiration + 1);
            if (_kpd1 > item.kpd) {
              item.kpd = _kpd1
              item.hedgeUnits = hedge;
              item.points = points;
            }
            /*
            if (_kpd2 > item.kpd) {
              item.kpd = _kpd2
              item.hedgeUnits = hedge;
              item.points = points;
            }*/
            if (minPnl >= 0) {
            }
            //}

            if (item.hedgeUnits < options.high && (maxPnlItem || item.maxPnl > maxPnlItem?.maxPnl)) {
              maxPnlItem = item
            }
            list.push(item);
          }
        }
      
      });
      }
    });
    //}
    //list = list.sort((a, b) => b.minPnl - a.minPnl);
    list = list.filter(x => x.points.length>=10 && x.points[0].pnl > x.points[0].pnlPerDay)
    //list = list.sort((a, b) => b.kpd - a.kpd);
    //list = list.sort((a, b) => (a.points[0].pnlPerDay - a.points[0].pnl) - (b.points[0].pnlPerDay - b.points[0].pnl));
    list = list.sort((a, b) => b.points[10].pnl  - a.points[10].pnl);
    //const list2 = list.filter(x => x.maxPnl > -500)
    //list2.sort((a, b) => b.minPnl - a.minPnl)
    return list.slice(0, 300).map((item, index) => ({ ...item, rank: index + 1 }))
  }

  return [];
}

export const calculate_one = (state: ScenarioDto, poolPrice: number, item: CalculationResult, hedge: number, width: number, shift: number, feePerDay: number, executionDay: number): Point[] => {
  const currentPrice = round(poolPrice, 2);
  const priceRange = currentPrice * (width / 100);
  //const shift = state.strategy.EdgePoints[1];
  const high = round(currentPrice + priceRange * ((100 - shift) / 100), 2);
  const low = round(currentPrice - priceRange * ((shift) / 100), 2);

  const days_to_expiration = item.daysToExpiration;
  const points = calculate(state.amount,
    currentPrice,
    low,
    high,
    hedge,
    item.item.strikePrice!,
    item.item.lowestAskPrice!,
    item.item.highestBidPrice!,
    days_to_expiration,
    feePerDay,
    executionDay
    );
  return points;
}