import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Alert, Button, Checkbox, Flex, Form, Input, InputNumber, Slider, Space, Spin, Switch, Tag, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { MobileSelect, SubmitButton } from "feature";
import {
  AttributeDescription,
  DictionaryService,
  ExchangeService,
  ExchangeType,
  LightExchangeResponse,
  NetDto,
  OptionSliceService,
  PoolAmount,
  PositionInfo,
  PositionService,
  ScenarioDto,
  ScenarioService,
  ScenarioSimpleDto,
  SwapperPoolItem,
  SwapperResponse,
  TokenDto,
  WalletService,
  round,
  useShowPopup,
} from "shared";
import { IResponseContainer, WalletModel } from "../../../positions/model";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { FormItemPrefixContext } from "antd/es/form/context";
import FormItem from "antd/es/form/FormItem";
import { CalculationResult } from "../../model";
import { calculate_best } from "../../functions";
import "../index.css"

const getFormItem = (attribute: AttributeDescription, props: any) => {
  if (attribute.type?.startsWith("RangeWithPointValue")) {
    return <Slider
      range
      style={{ marginTop: "40px" }}
      tooltip={{ open: true, overlay: false }}
      trackStyle={[
        { backgroundColor: '#ff5c53' },
        { backgroundColor: '#9dff69' },
      ]}
    />
  }
  else {
    switch (attribute.type) {
      case 'Decimal':
      case 'Int32':
      case 'Byte':
      case 'Float':
      case 'Double':
        return <InputNumber
          min={attribute.range?.minimum}
          max={attribute.range?.maximum}
          step={attribute.range?.minimum === 0 ? 1 : attribute.range?.minimum}
          {...props} />
      case "Boolean":
        return <Switch />
      default:
        return <Input minLength={attribute.range?.minimum ?? undefined} maxLength={attribute.range?.maximum ?? undefined} />
    }
  }
}

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: { span: 24, offset: 0 },
    sm: { span: 20, offset: 4 },
  },
};

export const ScenarioEdit = () => {
  const [form] = Form.useForm<ScenarioSimpleDto & { option: number, optionAmount: number }>();
  const { id } = useParams();
  const location = useLocation();
  const state = location.state as ScenarioDto;
  const nftCreated = state.isActive;

  // Получить список обменников
  const [swapper, setSwapper] = useState<SwapperResponse[] | null>(null);
  const [networks, setNetworks] = useState<NetDto[]>([]);
  const [tokensFirst, setTokensFirst] = useState<TokenDto[]>([]);
  const [tokensSecond, setTokensSecond] = useState<TokenDto[]>([]);
  const [wallets, setWallets] = useState<WalletModel[]>([]);
  const [exchange, setExchange] = useState<LightExchangeResponse[]>([]);
  const [attributes, setAttributes] = useState<Record<string, AttributeDescription>>({});
  const [editHedge, setEditHedge] = useState<boolean>(false);
  const [loadingOptions, setLoadingOptions] = useState<boolean>(false);
  const [topList, setTopList] = useState<CalculationResult[]>([]);


  const swapperValue = Form.useWatch("swapperId", form);
  const networkValue = Form.useWatch("netId", form);
  const needHedgeValue = Form.useWatch(["strategy", "NeedHedge"], form);

  useEffect(() => {
    if (editHedge) {
      setLoadingOptions(true);
      const pool = pools.find(x => x.feeTier === state.feeTier)
      OptionSliceService.getApiOptionsliceSliceAll({
        cexCode: state.userExchange.exchangeType === ExchangeType.BINANCE ? 1 : 2,
        asset: state.token0.realSymbol ?? state.token0.symbol!
      }).then((result) => {
        const data = result.data!;
        const top10 = calculate_best(state, pool?.currentPrice!, data, 1);
        setTopList(top10);
        console.log(top10)
        form.setFieldsValue({ option: 1, optionAmount: round(top10[0].hedgeUnits, 1) })
        setLoadingOptions(false);
      });
    }
  }, [editHedge])

  useEffect(() => {
    const swapperRequest = DictionaryService.getApiDictionarySwappers({}).then((result) => {
      setSwapper(result.data!);
    });
    const netsRequest = DictionaryService.getApiDictionaryNetworks({ swapperId: state.swapperId, }).then((result) => {
      setNetworks(result.data!);
    });
    const tokensRequest = DictionaryService.getApiDictionaryTokens({ netId: state.netId!, swapperId: state.swapperId! }).then((result) => {
      setTokensFirst(
        result.data?.filter((x) => x.isStableCoin === false) ?? []
      );
      setTokensSecond(
        result.data?.filter((x) => x.isStableCoin === true) ?? []
      );
    });
    const poolsRequest = DictionaryService.getApiDictionaryPools({
      netId: state.netId!,
      swapperId: state.swapperId!,
      token0Id: state.token0Id!,
      token1Id: state.token1Id!,
    }).then((result) => {
      setPools(result.data!);
    });
    const walletsRequest = WalletService.getApiWallet().then(
      (result: IResponseContainer<WalletModel[]>) => {
        setWallets(result?.data ?? []);
      }
    );
    const exchangesRequest = ExchangeService.getApiExchange().then((result) => {
      setExchange(result?.data!);
    });

    const attrRequest = ScenarioService.getApiScenarioStrategy({ name: state.strategyType! }).then((result) => {
      setAttributes(result?.data!);
    });

    Promise.all([swapperRequest, netsRequest, tokensRequest, poolsRequest, walletsRequest, exchangesRequest, attrRequest]).then((results) => {
      form.setFieldsValue({ ...state as any });
      setTimeout(() => { setLoading({ ...loading, all: false }) }, 1000);
    })
  }, []);

  // Получить список сетей 
  useEffect(() => {
    if (!loading.all && swapperValue) {

      setLoading({ ...loading, network: true });
      DictionaryService.getApiDictionaryNetworks({
        swapperId: swapperValue,
      }).then((result) => {
        setNetworks(result.data!);
        setLoading({ ...loading, network: false });
        setTokensFirst([]);
        setTokensSecond([]);
        setPools([]);
        form.setFieldsValue({
          netId: undefined,
          token0Id: undefined,
          token1Id: undefined,
          feeTier: undefined,
        });
      });
    }
  }, [swapperValue]);

  // Получить список токенов

  useEffect(() => {
    if (!loading.all && networkValue && swapperValue) {
      setLoading({ ...loading, token: true });
      DictionaryService.getApiDictionaryTokens({
        netId: networkValue,
        swapperId: swapperValue
      }).then((result) => {
        setTokensFirst(
          result.data?.filter((x) => x.isStableCoin === false) ?? []
        );
        setTokensSecond(
          result.data?.filter((x) => x.isStableCoin === true) ?? []
        );
        setLoading({ ...loading, token: false });

        setPools([]);
        form.setFieldsValue({
          token0Id: undefined,
          token1Id: undefined,
          feeTier: undefined,
        });
      });
    }
  }, [networkValue, swapperValue]);

  // Получить список пулов
  const [pools, setPools] = useState<SwapperPoolItem[]>([]);
  const tokenFirstValue = Form.useWatch("token0Id", form);
  const tokenSecondValue = Form.useWatch("token1Id", form);
  useEffect(() => {
    if (!loading.all && tokenFirstValue && tokenSecondValue) {
      setLoading({ ...loading, pool: true });
      DictionaryService.getApiDictionaryPools({
        netId: networkValue!,
        swapperId: swapperValue!,
        token0Id: tokenFirstValue,
        token1Id: tokenSecondValue,
      }).then((result) => {
        setPools(result.data!);
        setLoading({ ...loading, pool: false });
        form.setFieldsValue({
          feeTier: undefined,
        });
      });
    }
  }, [tokenFirstValue, tokenSecondValue]);

  const showPopUp = useShowPopup();
  const navigate = useNavigate();
  const onFinish = async (value: ScenarioSimpleDto & { option: number, optionAmount: number }) => {
    try {
      const selectedPool = pools.find(p => p.feeTier === value.feeTier);
      const obj = { ...value, id: Number.parseInt(id!), poolAddress: selectedPool!.id! }
      obj.strategy = { ...state.strategy, ...value.strategy }
      if (editHedge) {
        obj.strategy.Option = `${state.token0.realSymbol ?? state.token0.symbol!}-${topList[value.option - 1].expirationDate!.replace(/-/g, '').substring(2)}-${topList[value.option - 1].item.strikePrice}-P`
      }
      const response = await ScenarioService.putApiScenario({
        requestBody: obj,
      });

      if (response && !response?.isSuccess) {
        return showPopUp({
          title: "Ошибка",
          message: response?.errorText ?? "",
        });
      }

      navigate(-1);
    } catch (error: any) {
      if (error) {
        return showPopUp({ title: "Ошибка", message: error.message });
      }
    }
  };

  interface ILoading {
    all: boolean;
    swapper: boolean;
    network: boolean;
    token: boolean;
    pool: boolean;
    wallet: boolean;
    exchange: boolean;
  }
  const [loading, setLoading] = useState<ILoading>({
    all: true,
    swapper: false,
    network: false,
    token: false,
    pool: false,
    wallet: false,
    exchange: false,
  });

  const props = {
    min: 0,
    size: "large" as SizeType,
    type: "number",
    className: "w-full",
    disabled: loading.all,
  };

  return (
    <Spin tip="Загрузка..." spinning={loading.all}>
      <>
        <Alert
          type="warning"
          message={"Изменения будут применены только после перезапуска сценария"}
          className="mb-3"
        />
        <Form form={form} layout="vertical" autoComplete="off" onFinish={onFinish}>
          <Form.Item
            name="name"
            label={"Наименование"}
            rules={[{ required: true }]}
          >
            <Input {...props} type="text" />
          </Form.Item>
          <Form.Item name="swapperId" label="Обменник" rules={[{ required: true }]}>
            <MobileSelect
              loading={loading.swapper}
              disabled={nftCreated}
              options={swapper?.map((d) => ({
                value: d.id,
                label: d.name,
              }))}
              size="large"
            />
          </Form.Item>
          <Form.Item name="netId" label="Сеть" rules={[{ required: true }]}>
            <MobileSelect
              loading={loading.network}
              disabled={nftCreated || !swapperValue}
              options={networks?.map((d) => ({
                value: d.id,
                label: d.name,
              }))}
              size="large"
            />
          </Form.Item>
          <Form.Item name="token0Id" label="Токен 1" rules={[{ required: true }]}>
            <MobileSelect
              loading={loading.token}
              disabled={nftCreated || !networkValue}
              options={tokensFirst.map((d) => ({
                value: d.id,
                label: d.symbol,
              }))}
              size="large"
            />
          </Form.Item>
          <Form.Item
            name="token1Id"
            label="Токен 2"
            rules={[{ required: true }]}
          >
            <MobileSelect
              loading={loading.token}
              disabled={nftCreated || !networkValue}
              options={tokensSecond.map((d) => ({
                value: d.id,
                label: d.symbol,
              }))}
              size="large"
            />
          </Form.Item>

          <Form.Item name="feeTier" label="Пул" rules={[{ required: true }]}>
            <MobileSelect
              loading={loading.pool}
              disabled={nftCreated || !tokenFirstValue || !tokenSecondValue}
              options={pools?.map((d) => ({
                value: d.feeTier,
                label: d.name,
              }))}
              size="large"
            />
          </Form.Item>

          <Form.Item name="userWalletId" label="Кошель" rules={[{ required: true }]}>
            <MobileSelect
              disabled={nftCreated}
              options={wallets.map((d) => ({
                value: d.id,
                label: d.name || d.address
              }))}
              size="large"
            />
          </Form.Item>
          <Form.Item name="userExchangeId" label="Доступ" rules={[{ required: true }]}>
            <MobileSelect
              disabled={nftCreated}
              options={exchange.map((d) => ({
                value: d.id,
                label: d.name,
              }))}
              size="large"
            />
          </Form.Item>

          <Form.Item
            name="amount"
            label={"Сумма вклада"}
            rules={[{ required: true }]}
          >
            <InputNumber {...props} />
          </Form.Item>
          {Object.keys(attributes!).map((key: string) =>
            <Form.Item
              key={key}
              name={['strategy', `${key}`]}
              label={attributes[key].description ?? key}
              initialValue={attributes[key].defaultValue}
              rules={[{ required: attributes[key].required }]}
            >
              {
                getFormItem(attributes[key], { ...props })
              }
            </Form.Item>)
          }
          {needHedgeValue && state.strategy && state.strategy['@type'].indexOf('Options') >= 0 && (
            editHedge ?
              <Form.Item
                name={'option'}
                label="Опцион">
                <MobileSelect
                  loading={loadingOptions}
                  disabled={loadingOptions}
                  options={topList.map((d) => ({
                    value: d.rank!, //`${state.tokenFirst.realSymbol ?? state.tokenFirst.symbol!}-${expirationValue.replace('-', '')}- ${d.strikePrice}-P`,
                    display: `PUT ${d.expirationDate} ${d.item.strikePrice} ${d.item.lowestAskPrice}`,
                    label: <Flex style={{ flexGrow: 1 }} justify="space-between" align="center">
                      <Space> <Typography.Text type="danger">PUT</Typography.Text>
                        <Typography.Text strong>{d.item.strikePrice} </Typography.Text>
                      </Space>
                      <Tag color=" rgb(147 57 57)">{d.expirationDate}</Tag>

                      <Typography.Text strong type="success">{round(d.kpd * 100 / state.amount * 365,2)}</Typography.Text></Flex>,
                  }))}
                  //onChange={onOptionSelected}
                  size="large"
                /></Form.Item>
              :
              <Space style={{ display: 'flex', marginBottom: 8 }} align="center">
                <Form.Item
                  name={['strategy', 'Option']}
                  label="Опцион">
                  <Input {...props} type="text" />
                  {/*<Button type="primary">Submit</Button>*/}
                </Form.Item>
                <Button type="primary" onClick={() => setEditHedge(true)} >Изменить</Button>
              </Space>)}
          <SubmitButton form={form} text="Сохранить" isMain={true} />
        </Form>
      </>
    </Spin>
  );

};

