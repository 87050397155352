import { useLocation, useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Flex, Form, Space, Spin, Tag, Typography, Slider } from "antd";
import { MobileSelect, SubmitButton } from "feature";
import { CalculationResult, OptionFormModel, SecondFormModel } from "../../../model";
import { useEffect, useState } from "react";
import {
    ExchangeType,
    OptionSliceService,
    ScenarioService,
    DictionaryService,
    ScenarioSimpleDto,
    StrategyType,
    round,
    useShowPopup,
} from "shared";
import { Line } from "@ant-design/charts";
import { calculate_best, calculate_one } from "pages/mobile/scenarios/functions";
import { CustomInputNumber } from "feature/customInputNumber";


export const OptionSettingsPart = () => {
    const [form] = Form.useForm<OptionFormModel>();

    const location = useLocation();
    const state = location.state as SecondFormModel;

    interface ILoading {
        expiration: boolean;
        options: boolean;
    }
    const [loading, setLoading] = useState<ILoading>({
        expiration: false,
        options: false,
    });

    const [topList, setTopList] = useState<CalculationResult[]>([]);
    const [chartData, setChartData] = useState<any>({});
    const [currentPrice, setCurrentPrice] = useState<number>(state.pool.currentPrice!);
    const feePerDay = Form.useWatch("feePerDay", form);
    const option = Form.useWatch("option", form);
    const optionAmount = Form.useWatch("optionAmount", form);
    const width = Form.useWatch("width", form);
    const shift = Form.useWatch("shift", form);
    const date_now = Form.useWatch("flag", form);
    const executionDay = Form.useWatch("executionDay", form);

    useEffect(() => {        
        form.setFieldValue("feePerDay", 0.0)
    }, []);

    useEffect(() => {
        if (option) {
            const item = topList[option - 1];
            const dt = Date.now();
            form.setFieldsValue({
                optionAmount: round(item.hedgeUnits, 2),
                width: state.strategy.ChannelWidthPercent,
                shift: item.shift,
                executionDay: 1,
                flag: dt
            })
        }
    }, [option, topList]);


    useEffect(() => {
        setLoading({ ...loading, options: true });
        
        const p1 = DictionaryService.getApiDictionaryPools({
            netId: state.network!.id!,
            swapperId: state.swapper!.id!,
            token0Id: state.tokenFirst!.id!,
            token1Id: state.tokenSecond!.id!,
          });
        const p2 = OptionSliceService.getApiOptionsliceSliceAll({
            cexCode: state.exchange.type === ExchangeType.BINANCE ? 1 : 2,
            asset: state.tokenFirst.realSymbol ?? state.tokenFirst.symbol!
        });
        Promise.all([p1,p2]).then(x => {
            const poolResult = x[0];
            const result = x[1];
            const data = result.data!;
            if (data.length > 0) {
                const top10 = calculate_best(state as any, (poolResult.data!)[0]!.currentPrice!, data, 1);
                setTopList(top10);
                setCurrentPrice((poolResult.data!)[0].currentPrice!);
                if (top10 && top10.length > 0) {
                    form.setFieldsValue({
                        option: 1,
                        optionAmount: round(top10[0].hedgeUnits, 2),
                        width: state.strategy.ChannelWidthPercent,
                        shift: top10[0].shift,
                        executionDay: 1,
                        flag: Date.now()
                    })
                }
                else {
                    showPopUp({
                        title: "Ошибка",
                        message: "Нет подходящих опционов под данную ширину диапазона!",
                    });
                }
            } else {
                showPopUp({
                    title: "Ошибка",
                    message: "Опционы для этой пары не найдены!",
                });
            }
            setLoading({ ...loading, options: false });
        });
    }, []);

    useEffect(() => {
        if (option && topList && topList.length >= option) {     
            console.log(date_now)     
            const points = calculate_one(state as any, currentPrice, topList[option - 1], optionAmount, width, shift, feePerDay, executionDay??1)
            setChartData({
                data: [...points.map(x => ({ category:'1', price: x.price, pnl: x.pnl  })),
                       ...points.map(x => ({ category:'2', price: x.price, pnl: x.pnlPerDay }))],
                xField: 'price',
                yField: 'pnl',
                colorField:'category',
                legend: false,
                theme: "classicDark",
            })
        }
    }, [feePerDay, optionAmount, width, shift, executionDay, date_now])

   

    const showPopUp = useShowPopup();
    const navigate = useNavigate();
    const onFinish = async (values: any) => {
        const request: ScenarioSimpleDto = {
            netId: state.network.id!,
            swapperId: state.swapper.id!,
            feeTier: state.pool.feeTier!,
            poolAddress: state.pool.id!,
            token0Id: state.tokenFirst.id!,
            token1Id: state.tokenSecond.id!,
            userExchangeId: state.exchange.id!,
            userWalletId: state.wallet.id,
            amount: state.amount,
            strategyType: state.strategyType as StrategyType,
            name: state.name,
            isReversed: state.pool.reversed ?? false,
            strategy: {
                ...state.strategy,
                Option: `${state.tokenFirst.realSymbol ?? state.tokenFirst.symbol!}-${topList[option - 1].expirationDate!.replace(/-/g, '').substring(2)}-${topList[option - 1].item.strikePrice}-P`,
                OptionAmount: values.optionAmount,
                ChannelWidthPercent: width,                
            }
        };
        request.strategy.EdgePoints[1] = shift;
        delete request.strategy.name
        delete request.strategy.amount

        try {
            const response = await ScenarioService.postApiScenario({
                requestBody: request,
            });

            if (response && !response?.isSuccess) {
                return showPopUp({
                    title: "Ошибка",
                    message: response?.errorText ?? "",
                });
            }

            navigate(-3);
        } catch (error: any) {
            if (error) {
                return showPopUp({ title: "Ошибка", message: error.message });
            }
        }
    };

    /*
        const calculate = (slice: SliceDto): number => {
            const currentPrice = round(state.pool.currentPrice!, 2);
            const priceRange = currentPrice * (state.strategy.ChannelWidthPercent / 100);
            const priceHigh = round(currentPrice + priceRange * ((100 - state.strategy.ChannelEntryOffset) / 100), 2);
            const priceLow = round(currentPrice - priceRange * ((state.strategy.ChannelEntryOffset) / 100), 2);
            const calculations = calculate_optimum(state.amount, currentPrice, feePerDay, priceLow, priceHigh, slice)!
            return calculations;
        }
    */
    const onOptionSelected = (rank: number) => {

    }

    type SizeType = "small" | "middle" | "large" | undefined;
    const props = {
        size: "large" as SizeType,
        type: "number",
        className: "w-full",
        suffix: <>{loading.expiration && <LoadingOutlined style={{ fontSize: 12 }} spin />}</>,
    };

    return (
        <>
            <Alert
                type="info"
                message={
                    <Flex gap={'small'} vertical align="center">
                        <Spin spinning={loading.options} size="small">

                            <Line width={320} height={100} autoFit={true} {...chartData} />
                        </Spin>
                    </Flex>}
                className="mb-3"
            />
             {chartData && chartData.data && <Flex style={{paddingBottom:5}}  gap={5} align="stretch" justify="space-between" >
            <Typography.Text code type="danger">{`A:${round(chartData.data[0].pnl,2)}`}</Typography.Text>
            <Typography.Text code type="secondary"> {`min:${round(Math.min(...chartData.data.map((x:any)=>x.pnl)),2)}`}</Typography.Text>
            <Typography.Text code type="secondary"> {`max:${round(Math.max(...chartData.data.map((x:any)=>x.pnl)),2)}`}</Typography.Text>
            <Typography.Text code type="success"> {`B:${round(chartData.data[chartData.data.length-1].pnl,2)}`}</Typography.Text>
            </Flex>}
            <Typography.Title level={5} style={{ marginTop: 0 }}>
                Текущая цена: {round(currentPrice, 2)}
            </Typography.Title>
           
            <Form
                form={form}
                layout="vertical"
                autoComplete="off"
                onFinish={onFinish}
            >
                <Form.Item name="option" label="Опционы" rules={[{ required: true }]}>
                    <MobileSelect
                        loading={loading.options}
                        disabled={loading.options}
                        options={topList.map((d) => ({
                            value: d.rank!, //`${state.tokenFirst.realSymbol ?? state.tokenFirst.symbol!}-${expirationValue.replace('-', '')}- ${d.strikePrice}-P`,
                            display: `PUT ${d.expirationDate} ${d.item.strikePrice} ${d.item.lowestAskPrice}`,
                            label: <Flex style={{ flexGrow: 1 }} justify="space-between" align="center">
                                <Space> <Typography.Text type="danger">PUT</Typography.Text>
                                    <Typography.Text strong>{d.item.strikePrice} </Typography.Text>
                                </Space>
                                <Tag color=" rgb(147 57 57)">{d.expirationDate}</Tag>
                                <Typography.Text strong type="success">{round(d.kpd * 100 / state.amount * 365,2)}</Typography.Text></Flex>,
                        }))}
                        onChange={onOptionSelected}
                        size="large"
                    />
                </Form.Item>
                { topList && option && topList[option-1].daysToExpiration>1 &&
                <Form.Item name="executionDay" label={`Дней в работе: ${executionDay}`} >
                    <Slider {...props} step={1} min={1} max={topList[option-1].daysToExpiration}  /> 
                </Form.Item>
                }
                <Form.Item name="optionAmount" label="Количество units" rules={[{ required: true }]}>
                    <CustomInputNumber {...props} step={0.01} />
                </Form.Item>
                <Form.Item name="width" label="Ширина" rules={[{ required: true }]}>
                    <CustomInputNumber {...props} step={0.1} />
                </Form.Item>
                <Form.Item name="shift" label="Точка входа" rules={[{ required: true }]}>
                    <CustomInputNumber {...props} step={1} min={0} max={100} />
                </Form.Item>
                <Form.Item name="feePerDay" label="Дневной APR %" rules={[{ required: true }]}>
                    <CustomInputNumber min={0} max={10} step={0.1} {...props} />
                </Form.Item>
                <Form.Item name="flag" label="flag" hidden >
                    <CustomInputNumber {...props} />
                </Form.Item>
                <Form.Item>
                    <SubmitButton form={form} text="Сохранить" isMain={true} />
                </Form.Item>
            </Form>
        </>
    );
};
